import { render, staticRenderFns } from "./customerPoolDetail.vue?vue&type=template&id=39c0aea8&scoped=true&"
import script from "./customerPoolDetail.vue?vue&type=script&lang=js&"
export * from "./customerPoolDetail.vue?vue&type=script&lang=js&"
import style0 from "./customerPoolDetail.vue?vue&type=style&index=0&id=39c0aea8&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39c0aea8",
  null
  
)

export default component.exports